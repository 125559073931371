import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import { faCalendar, faTriangleExclamation, faCheck } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Nome", field:'name' },
	{ label:"Mese corrente", content:row => {
		const set = row?.missingDirectors?.month0?.set
		const tot = row?.missingDirectors?.month0?.tot
		const isOk = set >= tot
		return (
			<>
				<FontAwesomeIcon icon={isOk ? faCheck : faTriangleExclamation} color={isOk ? 'green' : 'red'} />
				&nbsp;
				{set} / {tot} direttori
			</>
		)
	}},
	{ label:"Mese successivo", content:row => {
		const set = row?.missingDirectors?.month1?.set
		const tot = row?.missingDirectors?.month1?.tot
		const isOk = set >= tot
		return (
			<>
				<FontAwesomeIcon icon={isOk ? faCheck : faTriangleExclamation} color={isOk ? 'green' : 'red'} />
				&nbsp;
				{set} / {tot} direttori
			</>
		)
	}},
]

export default function TrackList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleRefresh = () => api.call('shooting-range/list', {}).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('shooting-range/get', { _id })
	const today = new Date()

	const defBtns = [
		{
			icon: faCalendar,
			label: 'Visualizza mese',
			linkTo: row => '/bookings/' + row._id + '/' + today.getFullYear() + '/' + (today.getMonth() + 1),
		},
	]

	return (
		<Tpl title="Calendari poligoni">
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} />
		</Tpl>
	)
}
