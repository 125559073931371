import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import SelectMonth from './SelectMonth'
import { Typography } from '@mui/material';

import { faFilePdf, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'

const dows = [ 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato' ]

const useTabDef = (directors, shootingRanges) => {
	const evalDirector = (directorID) =>
		directorID && directors.find(director => director.value === directorID)?.label
	
	const evalDate = (shootingRangeId, bookings) => {
		const booking = bookings?.find(item => item.shootingRange === shootingRangeId) || {}
		const display = [
			<Typography variant='body2'>{evalDirector(booking?.director) || '-'}</Typography>
		]
		if(booking?.handling)
			display.push(<Typography variant='body2'>Maneggio: {evalDirector(booking?.handling)}</Typography>)
		if(booking?.supporter)
			display.push(<Typography variant='body2'>Affiancamento: {evalDirector(booking?.supporter)}</Typography>)
		if(booking?.message)
			booking.message
				.split('\n')
				.forEach(line =>
					display.push(<Typography variant='caption' sx={{display:'block'}}>{line}</Typography>)
				)
		return display
	}

	return useMemo(() => {
		const def = [
			{ label:"Data", content:row => row?.date?.split('-')?.reverse()?.join('/') },
			{ label:"Giorno", content:row => dows[new Date(row.date).getDay()] },
		]
		if(shootingRanges?.length)
			for(const shootingRange of shootingRanges)
				def.push({ label:shootingRange?.name, content:row => evalDate(shootingRange._id, row.bookings) })
		return def
	}, [ directors, shootingRanges ])
}

export default function TrackList() {
	const { year, month } = useParams()
	const api = useContext(APICtx)
	const [ dates, setDates ] = useState([])
	const [ directors, setDirectors ] = useState(null)
	const [ shootingRanges, setShootingRanges ] = useState(null)
	const tabDef = useTabDef(directors, shootingRanges)

	const handlePreviewPdf = () =>
		api.openFile({
			tag: 'booking/dates/pdf',
			params: { year, month },
		})
	const handleDownloadPdf = () =>
		api.openFile({
			tag: 'booking/dates/pdf',
			params: { year, month },
			download: 'prenotazioni-' + year + '-' + month + '.pdf',
		})
	const handleRefresh = () => api.call('booking/dates', { year, month }).then(setDates)
	useEffect(() => {
		api.call('director/list/options').then(directors => {
			setDirectors(directors)
		})
	}, [])
	useEffect(() => {
		api.call('shooting-range/list', {}).then(async shootingRangesIds => {
			const shootingRanges = await Promise.all(
				shootingRangesIds.map(async _id =>
					api.call('shooting-range/get', { _id })
				)
			)
			setShootingRanges(shootingRanges)
		})
	}, [])
	useEffect(() => {
		if(directors !== null && shootingRanges !== null)
				handleRefresh()
	}, [ directors, shootingRanges, year, month ])

	const getRow = async date => api.call('booking/get-all', { date })

	const tableActions = [
		{ icon:faFilePdf, label:'Scarica', onClick:() => handleDownloadPdf() },
		{ icon:faMagnifyingGlass, label:'Anteprima', onClick:() => handlePreviewPdf() },
	]

	const title = 'Riepilogo mensile'
	return (
		<Tpl title={title}>
			<SelectMonth baseLink={'/bookings-summary'} year={year} month={month} />
			<DataList def={tabDef} ids={dates} getRow={getRow} listActions={tableActions} />
		</Tpl>
	)
}
