import React from 'react'

import Form, { Input } from '#Form'
import Dialog from '#Dialog'

const EditForm = ({ _id, competition }) => {
	const formDef = {
		_id,
		callSet: _id ? "competition/participant/update" : "competition/participant/create",
		callGet: _id && "competition/participant/get",
		extraData: { competition },
	}

	return (
		<Form {...formDef}>
			<Input label="Nominativo" name="label" required />
		</Form>
	)
}

const EditParticipant = ({ _id, competition, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh?.() : handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " partecipante" }
			width="md"
		>
			<EditForm _id={_id} competition={competition} />
		</Dialog>
	)
}
export default EditParticipant
