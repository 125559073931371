import React from 'react';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import Button from '#Button'

import { faChevronLeft, faChevronRight, faCalendar } from '@fortawesome/pro-regular-svg-icons'

const HeadNav = ({ curLabel, rstIcon, rstLink, prevLink, nextLink }) => (
	<Box sx={{ display:'flex', justifyContent:'space-between', paddingTop:2, paddingBottom:2, paddingLeft:1, paddingRight:1 }}>
		<Box sx={{ mt:'auto', mb:'auto' }}>
			<Button icon={faChevronLeft} linkTo={prevLink} />
		</Box>
		<Box>
			<Typography variant='h4'>
				<Button icon={rstIcon || faCalendar} linkTo={rstLink} />
				{curLabel}
			</Typography>
		</Box>
		<Box sx={{ mt:'auto', mb:'auto' }}>
			<Button icon={faChevronRight} linkTo={nextLink} />
		</Box>
	</Box>
)
export default HeadNav