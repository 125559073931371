import React from 'react';

import HeadNav from '#HeadNav'

export const months = [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ]

const SelectMonth = ({ baseLink, year, month }) => {
	const now = new Date()
	const curYear = now.getFullYear()
	const curMonth = now.getMonth() + 1

	const todayLink = baseLink + '/' + curYear + '/' + curMonth
	const prevDate = new Date(year, parseInt(month) - 1, 1).toISOString().slice(0, 7).split('-').join('/')
	const prevLink = baseLink + '/' + prevDate
	const nextDate = new Date(year, parseInt(month) + 1, 1).toISOString().slice(0, 7).split('-').join('/')
	const nextLink = baseLink + '/' + nextDate

	return (
		<HeadNav
			curLabel={months[month-1] + ' ' + year}
			prevLink={prevLink}
			rstLink={todayLink}
			nextLink={nextLink}
		/>
	)
}
export default SelectMonth