import React, { useEffect, useState, useContext } from 'react'

import APICtx from '#api'
import Form, { InputNumber, Select } from '#Form'
import Dialog from '#Dialog'

const scoresOpts = Array(11).fill().map((_, idx) => ({
	value: idx,
	label: idx,
})).concat([{
	value: 'M',
	label: 'Mouche',
}])

const EditForm = ({ _id, participant, competition }) => {
	const api = useContext(APICtx)
	const [ competitionData, setCompetitionData ] = useState()

	useEffect(() => {
		api.call('competition/get', { _id:competition }).then(setCompetitionData)
	}, [])

	const formDef = {
		_id,
		callSet: _id ? "competition/manche/update" : "competition/manche/create",
		callGet: _id && "competition/manche/get",
		extraData: {
			participant,
			shotsQuantity: competitionData?.shotsQuantity,
		},
	}

	return (
		<Form {...formDef}>
			{ competitionData?.shotsQuantity && Array(competitionData.shotsQuantity / 10000).fill().map((item, itemIndex) => (
				<Select
					label={"Punti tiro #" + (itemIndex+1).toString()}
					name={"shots.n" + (itemIndex.toString().padStart(3, '0'))}
					options={scoresOpts}
				/>
			))}
			<InputNumber label="Punti penalità" name="penalty" decimals={0} />
		</Form>
	)
}

const EditManche = ({ _id, participant, competition, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh?.()
		handleRefresh?.()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Inserimento") + " colpi" }
			width="sm"
		>
			<EditForm _id={_id} participant={participant} competition={competition} />
		</Dialog>
	)
}
export default EditManche
