import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import {
	faTriangleExclamation,
	faUserPen,
} from '@fortawesome/pro-regular-svg-icons'
import SelectMonth from './SelectMonth'
import EditDirector from './EditDirector'

const dows = [ 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato' ]

const useTabDef = (directors) => {
	const evalDirector = (directorID) =>
		directorID && directors.find(director => director.value === directorID)?.label

	return useMemo(() => ([
		{ label:"Data", content:row => row?.date?.split('-')?.reverse()?.join('/') },
		{ label:"Giorno", content:row => dows[new Date(row.date).getDay()] },
		{ label:"Direttore", content:row =>
			evalDirector(row.director) || <FontAwesomeIcon icon={faTriangleExclamation} color="red" />
		},
		{ label:"Maneggio", content:row => evalDirector(row.handling) || '-' },
		{ label:"Affiancamento", content:row => evalDirector(row.supporter) || '-' },
		{ label:"Messaggio", field:'message', type:'multiline' },
	]), [ directors ])
}

export default function TrackList() {
	const { shootingRange, year, month } = useParams()
	const api = useContext(APICtx)
	const [ dates, setDates ] = useState([])
	const [ editDirector, setEditDirector ] = useState()
	const [ directors, setDirectors ] = useState([])
	const [ shootingRangeInfo, setShootingRangeInfo ] = useState({})
	const tabDef = useTabDef(directors)

	const handleRefresh = () => api.call('booking/dates', { shootingRange, year, month }).then(setDates)
	useEffect(() => {
		api.call('shooting-range/get', { _id:shootingRange }).then(setShootingRangeInfo)
	}, [])
	useEffect(() => {
		api.call('director/list/options').then(directors => {
			setDirectors(directors)
			handleRefresh()
		})
	}, [ year, month ])

	const getRow = async date => {
		const { _id:booking, ...data } = await api.call('booking/get', { shootingRange, date })
		return { ...data, booking }
	}

	const isAdmin = api.auth.chk('bookings-admin')

	const defBtns = []
	isAdmin && defBtns.push({
		icon: faUserPen,
		label: 'Imposta direttore',
		onClick: setEditDirector,
	})

	const title = 'Prenotazioni poligono' + (shootingRangeInfo?.name ? ' - '+shootingRangeInfo.name : '')
	return (
		<Tpl title={title} backTo="/bookings">
			<EditDirector handleRefresh={handleRefresh} handleClose={() => setEditDirector(null)} {...editDirector} />
			<SelectMonth baseLink={'/bookings/' + shootingRange} year={year} month={month} />
			<DataList def={tabDef} ids={dates} getRow={getRow} rowActions={defBtns} />
		</Tpl>
	)
}
