import React, { useState } from 'react'

import Form, { Input, InputDate, InputNumber } from '#Form'
import Dialog from '#Dialog'

const EditForm = ({ _id }) => {
	const formDef = {
		_id,
		callSet: _id ? "competition/update" : "competition/create",
		callGet: "competition/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="name" required />
			<InputDate label="Data" name="date" required />
			<InputNumber label="Colpi per manche" name="shotsQuantity" decimals={0} required disabled={_id} />
			<InputNumber label="Colpi scartati" name="discardsQuantity" decimals={0} required disabled={_id} />
		</Form>
	)
}

const EditCompetition = ({ _id, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh?.() : handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " competizione" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default EditCompetition
