import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faPlus, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'
import EditShootingRange from './Edit'

const tabDef = [
	{ label:"Nome", field:'name' },
	{ label:"Lun", field:'openingDOW1', type:'boolean', falseIcon:null },
	{ label:"Mar", field:'openingDOW2', type:'boolean', falseIcon:null },
	{ label:"Mer", field:'openingDOW3', type:'boolean', falseIcon:null },
	{ label:"Gio", field:'openingDOW4', type:'boolean', falseIcon:null },
	{ label:"Ven", field:'openingDOW5', type:'boolean', falseIcon:null },
	{ label:"Sab", field:'openingDOW6', type:'boolean', falseIcon:null },
	{ label:"Dom", field:'openingDOW0', type:'boolean', falseIcon:null },
]

export default function TrackList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editShootingRange, setEditShootingRange ] = useState()

	const handleRefresh = () => api.call('shooting-range/list', {}).then(setIds)
	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare la pista "' + name + '"?',
			onConfirm: () => api.call('shooting-range/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('shooting-range/get', { _id })
	const isAdmin = api.auth.chk('shooting-ranges-admin')

	const defBtns = []
	isAdmin && defBtns.push(
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditShootingRange,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	)

	return (
		<Tpl title="Gestione poligoni">
			<EditShootingRange handleRefresh={handleRefresh} handleClose={() => setEditShootingRange(null)} {...editShootingRange} />
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			{ isAdmin && <Fab label="Crea poligono" icon={faPlus} onClick={() => setEditShootingRange({ _id:null })} /> }
		</Tpl>
	)
}
