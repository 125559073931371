import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faPlus, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'
import EditDirector from './Edit'

const tabDef = [
	{ label:"Cognome", field:'lastname' },
	{ label:"Nome", field:'firstname' },
]

export default function DirectorList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editDirector, setEditDirector ] = useState()

	const handleRefresh = () => api.call('director/list', {}).then(setIds)
	const handleDelete = ({ _id, firstname, lastname }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare il direttore "' + lastname + ' ' + firstname + '"?',
			onConfirm: () => api.call('director/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('director/get', { _id })
	const isAdmin = api.auth.chk('directors-admin')

	const defBtns = []
	isAdmin && defBtns.push(
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditDirector,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	)
	
	return (
		<Tpl title="Gestione direttori">
			<EditDirector handleRefresh={handleRefresh} handleClose={() => setEditDirector(null)} {...editDirector} />
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			{ isAdmin && <Fab label="Crea direttore" icon={faPlus} onClick={() => setEditDirector({ _id:null })} /> }
		</Tpl>
	)
}
