import React from 'react'

import Form, { Input } from '#Form'
import Dialog from '#Dialog'


const EditForm = ({ _id }) => {
	const formDef = {
		_id,
		callSet: _id ? "director/update" : "director/create",
		callGet: _id && "director/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="firstname" required />
			<Input label="Cognome" name="lastname" required />
		</Form>
	)
}

const EditDirector = ({ _id, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh?.() : handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " direttore" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default EditDirector
