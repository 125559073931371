import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faEdit, faTrash, faBullseye } from '@fortawesome/pro-regular-svg-icons'
import EditManche from './EditManche';

const useTabDef = ({ shotsQuantity }) => useMemo(() => {
	const qty = shotsQuantity / 10000
	const def = [
		{ label:"Totale", content:row => row?.total?.points + ' + ' + row?.total?.mouches + 'M' }
	]
	for(let i = 0; i < qty; i++) {
		def.push({
			label: '#'+(i+1).toString(),
			content:row => row.shots['n' + (i.toString().padStart(3, '0'))] || '0',
			cellAlign: 'center',
		})
	}
	def.push({ label:"Penalità", field:'penalty', type:'integer' })
	return def
}, [ shotsQuantity ])

export default function MAnches() {
	const api = useContext(APICtx)
	const { competition, participant } = useParams()
	const [ ids, setIds ] = useState([])
	const [ competitionData, setCompetitionData ] = useState({})
	const [ participantData, setParticipantData ] = useState({})
	// const [ editParticipant, setEditParticipant ] = useState()
	const [ editManche, setEditManche ] = useState()
	const tabDef = useTabDef(competitionData)

	const handleRefresh = () => api.call('competition/manche/list', { participant }).then(setIds)
	const handleDelete = ({ _id }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare la prova selezionata?',
			onConfirm: () => api.call('competition/manche/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [ competition ])
	useEffect(() => {
		api.call('competition/get', { _id:competition }).then(setCompetitionData)
		api.call('competition/participant/get', { _id:participant }).then(setParticipantData)
	}, [])

	const getRow = async _id => api.call('competition/manche/get', { _id })

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica manche',
			onClick: setEditManche,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]
	
	const title =
		( competitionData?.name ? competitionData.name+' - ' : '' ) +
		( participantData?.name ? participantData.name+' - ' : '' ) +
		'Dettaglio manche'
	const backTo = '/competitions/' + competition
	return (
		<Tpl title={title} backTo={backTo}>
			<EditManche
				handleRefresh={handleRefresh}
				handleClose={() => setEditManche(null)}
				{...editManche}
				competition={competition}
			/>
			{ Boolean(competitionData?.shotsQuantity) && <DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} /> }
			<Fab label="Aggiungi manche" icon={faBullseye} onClick={() => setEditManche({ _id:null, participant })} />
		</Tpl>
	)
}
