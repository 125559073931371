import React from 'react'
import Typography from '@mui/material/Typography'
import Form, { Input, Checkbox } from '#Form'
import Dialog from '#Dialog'


const EditForm = ({ _id }) => {
	const formDef = {
		_id,
		callSet: _id ? "shooting-range/update" : "shooting-range/create",
		callGet: _id && "shooting-range/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="name" required />
			<Typography>Giorni di apertura</Typography>
			<Checkbox label="Lunedì" name="openingDOW1" />
			<Checkbox label="Martedì" name="openingDOW2" />
			<Checkbox label="Mercoledì" name="openingDOW3" />
			<Checkbox label="Giovedì" name="openingDOW4" />
			<Checkbox label="Venerdì" name="openingDOW5" />
			<Checkbox label="Sabato" name="openingDOW6" />
			<Checkbox label="Domenica" name="openingDOW0" />
		</Form>
	)
}

const EditTrack = ({ _id, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh?.() : handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " poligono" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default EditTrack
