import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
import logoImg from './logo.png'

import BookingSummary from './booking/MonthSummary'
import BookingListPage from './booking/List'
import BookingMonthPage from './booking/MonthCal'
import DirectorListPage from './director/List'
import SRangeListPage from './shooting-range/List'

import Competitions from './competitions/Competitions'
import Participants from './competitions/Participants'
import Manches from './competitions/Manches'

import { faCalendar, faBuildingFlag, faUserPolice } from '@fortawesome/pro-solid-svg-icons';
import { faBullseyeArrow, faPenField } from '@fortawesome/pro-regular-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/bookings/:shootingRange/:year/:month" element={<BookingMonthPage />} />
		<Route path="/bookings" element={<BookingListPage />} />
		
		<Route path="/bookings-summary/:year/:month" element={<BookingSummary />} />
		<Route path="/bookings-summary" element={<RedirectToNow baseLink="/bookings-summary" />} />

		<Route path="/directors" element={<DirectorListPage />} />
		<Route path="/shooting-ranges" element={<SRangeListPage />} />

		<Route path="/competitions/:competition/manches/:participant" element={<Manches />} />
		<Route path="/competitions/:competition" element={<Participants />} />
		<Route path="/competitions" element={<Competitions />} />
		
		<Route path="/" element={<Blank />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Tiro a segno" />
)

const RedirectToNow = ({ baseLink }) => {
	const navigate = useNavigate()

	const now = new Date()
	const curYear = now.getFullYear()
	const curMonth = now.getMonth() + 1

	useEffect(() => {
		const todayLink = baseLink + '/' + curYear + '/' + curMonth
		navigate(todayLink, { replace:true })
	})
}

const useMenu = (api) => useMemo(() => {
	const menu = []

	const directorsMenu = []
	if(api.auth.chk('bookings-view'))
		directorsMenu.push(
			{ label:"Gestici calendari", linkTo:"/bookings", icon:faPenField },
			{ label:"Vista mensile", linkTo:"/bookings-summary", icon:faCalendar },
		)
	if(api.auth.chk('shooting-ranges-admin'))
		directorsMenu.push({ label:"Gestione poligoni", linkTo:"/shooting-ranges", icon:faBuildingFlag })
	if(api.auth.chk('directors-admin'))
		directorsMenu.push({ label:"Direttori", linkTo:"/directors", icon:faUserPolice })
	if(directorsMenu.length)
		menu.push(directorsMenu)

	const contestsMenu = []
	if(api.auth.chk('competitions-admin'))
		contestsMenu.push({ label:"Gare", linkTo:"/competitions", icon:faBullseyeArrow })
	if(contestsMenu.length)
		menu.push(contestsMenu)

	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu} logo={logoImg}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}
